<template>
    <div class="stationView">
      <main class="player-content flex-row">
        <section class="flex-1">
  
  <div class="flex-autorow flex-top flex-stretch player-channel">
    <div class="flex-item flex-1">
      <div class="push-bottom fx fx-slide-up fx-delay-3">
            <div class="push-bottom">
        <h4 class="text-nowrap fx fx-slide-left fx-delay-1">Partenaires</h4>
        <h5 style="font-size: 0.73rem;" class="text-nowrap fx fx-slide-left fx-delay-1">Liste des partenaires de Radio Enghien</h5>
      </div>
      </div>
    </div>
    </div>
  
          <div class="flex-autorow flex-top flex-stretch player-channel">
            <div class="flex-item flex-1">
        <div class="card_home push-bottom fx fx-slide-up fx-delay-3">
    <div class="push-bottom" style="align-items: center !important;    justify-content: space-between !important;    display: flex !important;">
      <h5 class="text-nowrap fx fx-slide-left fx-delay-1">Emissions de ce {{ currentDay }}</h5>     
    </div>
    <hr style="color:#fff;border-bottom: 2px solid rgb(255 255 255);">
    <div id="test" style="display:flex;flex-wrap: wrap;"> 

<li class="fx-slide-left fx-delay-1 card fx flex-row flex-top flex-stretch flex-1" style="margin-right: 10px !important;max-width: calc(33.33% - 20px);">

    <div class="weekly2-single slick-slide slick-active" data-slick-index="1" aria-hidden="false" style="width: 244px;margin-left: auto;margin-right: auto;" tabindex="0" >
                                            <div class="weekly2-img" style="padding: 15px;">
                                                <img :src="''+program.image+''" :alt="''+program.title+''" style="width: 100%; height: 200px; border-radius: 5px; object-fit: cover;">
                                            </div>	
											
											<a href="emissions" tabindex="0">
                                            <div class="weekly2-caption" style="padding-bottom: 5px;margin-top: 5px;">
											<center>
											
                                                <h5 style="margin-top: 5px; font-size: 16px; font-weight: bold; text-transform : uppercase; color: #fff" id="bloc_texte_principal_clips_1_2">{{ program.title }}</h5>
												<p style="margin-top: -10px; font-size: 16px; text-transform : uppercase; color: #262626" id="bloc_texte_principal_clips_2_2">{{ program.description }}</p>
												
												<div style="margin-top: 6px;"><span class="bgbeg" style="padding-bottom: 5px;border-radius: 5px;" id="elementspricipaux_fond_clips2"><b><font style="font-size:12px; color: #FFFF00; padding: 10px;" id="elementspricipaux_texte_clips2">{{ program.time }}</font></b></span></div>
												
                                            </center>
											</div></a>
                                        </div>
</li>
    </div>
  </div>
</div>
      <!--
      <div class="flex-item flex-1">
  
      <div class="card push-bottom fx fx-slide-up fx-delay-3">
        <a href="https://ythomas.fr"><img width="135px" src="img/partenaires/ythomas.svg"></a>
      </div>
  
    </div>
  
      <div class="flex-item flex-1">
  
      <div class="card push-bottom fx fx-slide-up fx-delay-3">
        <a href="https://discord.com/invite/sky-529993698210086922"><img width="100px" src="img/partenaires/sky.png"></a>
      </div>
  
    </div>
    -->
    </div>
  
  
     </section>
      </main>
  
    </div>
  </template>
  
  <script>
import axios from 'axios'

export default {
  name: 'PodcastDetails',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      podcast: {}
    }
  },
  created() {
    this.fetchPodcast()
  },
  methods: {
    async fetchPodcast() {
      try {
        const response = await axios.get(`https://api.example.com/podcasts/${this.id}`)
        this.podcast = response.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
  
  <style scoped>
  
  </style>